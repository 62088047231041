import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import Events from '../components/Events/Events';

const EventsPage = () => {
    return (
        <>
            <SEO title="All Events" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">All Events</h3>
                <Pageheader />
                <Events fullpage />
            </MDBContainer>
        </>
    );
};

export default EventsPage;